/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react"
import { DataTable } from "primereact/datatable"
import { ShopAdminTableType, ShopAdminType } from "./types"
import { Column } from "primereact/column"
import ImgCustom from "src/components/img-custom"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"

export default function ShopAdminTable(props: ShopAdminTableType) {
  const { shops, myShopId, isSpecShop } = props
  const { t } = useTranslation()
  const AvatarTemplate = (rowData: ShopAdminType) => {
    return (
      <div>
        <ImgCustom
          url={rowData.avatar_thumbnail_url}
          alt="shopImage"
          isShopImage={true}
          className="h-[40px] w-[40px] rounded-full"
        />
      </div>
    )
  }
  const NameTemplate = (rowData: ShopAdminType) => {
    return (
      <span className="inline-block font-14 max-w-[280px] font-medium leading-20 text-gray-700 whitespace-normal">
        {rowData.name}
      </span>
    )
  }
  const EmailTemplate = (rowData: ShopAdminType) => {
    return (
      <span className="inline-block font-14 max-w-[300px] font-medium leading-20 text-gray-700 break-words">
        {rowData.email}
      </span>
    )
  }
  const ActionTemplate = (rowData: ShopAdminType) => {
    const handleGo = () => {
      let url = `${process.env.REACT_APP_ECOMMERCE_MANAGEMENT_DOMAIN_URL}/shops/${rowData.id}?permission=user`
      if(isSpecShop && myShopId === rowData.id) {
        url = `${process.env.REACT_APP_SPECIAL_SHOP_URL}/shops/${rowData.id}?permission=user`
      }
      window.open(url, "_self")
    }
    return (
      <div className="flex items-center justify-end">
        <button
          className="rounded-3 bg-blue-600 px-3 py-1 text-[14px] font-medium leading-[20px] text-white hover:bg-blue-500"
          onClick={handleGo}
        >
          {capitalizedFirst(t("home-page.go"))}
        </button>
      </div>
    )
  }

  const tableFields = [
    {
      field: "avatar_thumbnail_url",
      label: "",
      customStyle: {
        width: "100px",
        minWidth: "100px",
      },
      template: AvatarTemplate,
    },
    {
      field: "name",
      label: capitalizedFirst(t("home-page.shop-name")),
      customStyle: {},
      template: NameTemplate,
    },
    {
      field: "email",
      label: capitalizedFirst(t("home-page.shop-email")),
      customStyle: {
        width: "150px",
      },
      template: EmailTemplate,
    },
    {
      field: "id",
      label: "",
      customStyle: {
        width: "70px",
      },
      template: ActionTemplate,
    },
  ]
  if (!shops.length) return null
  return (
    <div className="shopping-product-table w-full" id="customTable">
      <DataTable value={shops} dataKey="id">
        {tableFields.map((item, index) => (
          <Column
            key={index}
            field={item.field}
            header={item.label}
            bodyStyle={item.customStyle}
            body={item.template}
          />
        ))}
      </DataTable>
    </div>
  )
}
