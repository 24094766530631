import http from "src/config/interceptors"

export const getUserInfo = (userId: string) => {
  return http.get(`/user/users/${userId}`)
}

export const detectUserAdmin = (userId: string) => {
  return http.get(
    `/user-permission/api-permissions/users/${userId}/permissions`
  )
}

export const getMyShop = () => {
  const userId = localStorage.getItem("userId")
  return http.get(`/shop/shops/users/${userId}`)
}
